import { defineStore } from 'pinia'

export const useModalStore = defineStore('modalStore', () => {
    const showLoginModal = ref(false)
    const showLogoutModal = ref(false)
    const showSignUpModal = ref(false)
    const showVerifyOTPModal = ref(false)
    const showForgotPasswordModal = ref(false)
    const showAddBankModal = ref(false)
    const showBankAddedModal = ref(false)
    const showVerfiyPhoneModal = ref(false)
    const showPhoneVerifiedModal = ref(false)

    const showVerifyEmailModal = ref(false)
    const showVerifyEmailOtpModal = ref(false)
    const showEmailVerifiedModal = ref(false)

    const showVerifyLineModal = ref(false)

    const showVerifyTelegramModal = ref(false)

    const showSelectBankModal = ref(false)

    const showSearchModal = ref(false)
    const showLeftBar = ref(false)

    const showGameDenyModal = ref(false)

    const showCancelDepositModal = ref(false)
    const showCancelPromoModal = ref(false)
    const showFundsFunctionModal = ref(false)
    const showSupportFunctionModal = ref(false)
    const showKycRequired = ref(false)
    const showKycVerificationPending = ref(false)

    const showPromoBtiModal = ref(false)
    const showOneTimeVerificationBank = ref(false)

    const showCashbackRebateModal = ref(false)

    const showNoBankModal = ref(false)
    const showVerifyBankModal = ref(false)

    const showCancelCodepayModal = ref(false)
    const startTimer = ref(0)
    const closeRegistrationReason = ref('')
    const useNuxtAppInstance = useNuxtApp()
    watch(showSignUpModal, (isOpen) => {
        if (isOpen) {
            startTimer.value = Date.now()
            const postHog = useNuxtAppInstance.$posthog()
            if (postHog) {
                postHog.capture('registration_modal_open')
            }
        } else if (startTimer.value) {
            const endTimer = Date.now()
            const timeSpent = (endTimer - startTimer.value) / 1000
            const postHog = useNuxtAppInstance.$posthog()
            if (postHog) {
                postHog.capture('registration_modal_close', {
                    time_spent: timeSpent,
                    reason: closeRegistrationReason.value,
                })
            }

            startTimer.value = 0
            closeRegistrationReason.value = ''
        }
    })

    return {
        showLoginModal,
        showLogoutModal,

        showSignUpModal,
        showVerifyOTPModal,
        showForgotPasswordModal,
        showAddBankModal,
        showBankAddedModal,
        showVerfiyPhoneModal,
        showPhoneVerifiedModal,

        showVerifyEmailModal,
        showVerifyEmailOtpModal,
        showEmailVerifiedModal,

        showVerifyLineModal,

        showVerifyTelegramModal,

        showSelectBankModal,

        showSearchModal,
        showLeftBar,

        showGameDenyModal,

        showCancelDepositModal,
        showCancelPromoModal,
        showFundsFunctionModal,
        showSupportFunctionModal,
        showKycRequired,
        showKycVerificationPending,

        showPromoBtiModal,
        showOneTimeVerificationBank,

        showCashbackRebateModal,

        showNoBankModal,
        showVerifyBankModal,

        showCancelCodepayModal,
        closeRegistrationReason,
    }
})
